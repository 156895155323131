import React from 'react';
import cx from 'clsx';
import { IconChevronRightOutlined, IconCrossOutlined } from '@repo/monochrome-icons';
import styles from './flow-header.strict-module.css';

interface StepProps {
  active?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  title: string;
}

export interface FlowHeaderProps {
  actions?: React.ReactNode;
  breadcrumb: StepProps[];
  className?: string;
  onClose: () => void;
  title: string;
}

function Step({ active, disabled, onClick, title }: StepProps): React.ReactNode {
  const notClickable = active || disabled;

  const handleClick = (): void => {
    if (notClickable) {
      return;
    }
    onClick?.();
  };

  return (
    <button
      aria-disabled={notClickable}
      className={cx(
        'body-1',
        styles.step,
        active && styles.stepActive,
        disabled && styles.stepDisabled
      )}
      onClick={handleClick}
      type="button"
    >
      {title}
    </button>
  );
}

export function FlowHeader({
  actions,
  breadcrumb,
  className,
  onClose,
  title,
}: FlowHeaderProps): React.ReactNode {
  return (
    <header className={cx(styles.header, className)} data-test-flow-header="">
      <h2 className="title-2" data-test-flow-header-title="">{title}</h2>
      <div className={styles.breadcrumb}>
        {breadcrumb.map((step, index) => (
          <>
            <Step key={step.title} {...step} />
            {Boolean(index < breadcrumb.length - 1) && <IconChevronRightOutlined />}
          </>
        ))}
      </div>
      <div className={styles.actions}>
        {actions}
        <button
          aria-label="Close"
          className={styles.button}
          data-test-flow-header-close-button=""
          onClick={onClose}
          type="button"
        >
          <IconCrossOutlined />
        </button>
      </div>
    </header>
  );
}
