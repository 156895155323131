import { Button, CloseIcon, Tooltip } from '@repo/design-system-kit';
import { useIntl } from 'react-intl';
import type { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from '../../shared';
import styles from './card.strict-module.css';
import { SuggestedTransactionCardSkeleton } from './skeleton';

interface Transaction {
  localAmount?: number;
  localAmountCurrency?: string;
  settledAt?: string;
  counterpartyName?: string;
  localizedOperationMethod?: ReactElement;
}

interface SuggestedTransactionCardProps {
  isLoading: boolean;
  transaction?: Transaction;
  handleMatchTransaction: () => void;
  handleDismissSuggestion: () => void;
}

export function SuggestedTransactionCard({
  transaction,
  handleDismissSuggestion,
  handleMatchTransaction,
  isLoading,
}: SuggestedTransactionCardProps): ReactNode {
  const { formatDate, formatNumber, formatMessage } = useIntl();

  if (isLoading || !transaction) {
    return <SuggestedTransactionCardSkeleton />;
  }

  const formattedDate = transaction.settledAt
    ? formatDate(new Date(transaction.settledAt), {
        weekday: 'short',
        month: 'short',
        day: '2-digit',
      })
    : null;

  const formattedAmount = transaction.localAmount
    ? `-\xA0${formatNumber(transaction.localAmount, {
        style: 'decimal',
        minimumFractionDigits: 2,
      })}\xA0${transaction.localAmountCurrency}`
    : null;

  return (
    <div className={styles.card}>
      <p className={styles.title}>{transaction.counterpartyName}</p>
      <p className={styles.amount}>{formattedAmount}</p>
      <p className={styles.subtitle}>
        {formattedDate} · {transaction.localizedOperationMethod}
      </p>
      <div className={styles.actions}>
        <Button data-testid="match-transaction-cta" onPress={handleMatchTransaction}>
          <FormattedMessage id="supplier-invoices.suggested-transaction.match-transaction.cta" />
        </Button>
        <Tooltip
          label={formatMessage({
            id: 'supplier-invoices.suggested-transaction.ignore-transaction.tooltip',
          })}
          placement="top"
        >
          <Button
            aria-label={formatMessage({
              id: 'supplier-invoices.suggested-transaction.ignore-transaction.tooltip',
            })}
            data-testid="dismiss-suggestion-cta"
            iconOnly
            onPress={handleDismissSuggestion}
          >
            <CloseIcon />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
}
