import type { ReactNode, HTMLAttributes } from 'react';
import { useIntl } from 'react-intl';
import { IconCrossOutlined } from '@repo/monochrome-icons';
import cx from 'clsx';
import { Button } from '../../button';
import styles from './fullscreen-base.strict-module.css';

interface FullscreenBaseProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  /**
   * Replace the header.
   * @see Removes also the close button
   */
  header?: ReactNode;
  hasLayout?: boolean;
  onClose?: () => void;
}

export function FullscreenBase({
  children,
  header,
  hasLayout,
  onClose,
  ...props
}: FullscreenBaseProps): ReactNode {
  const intl = useIntl();
  const headerClass = !hasLayout ? 'close-wrapper--base' : 'close-wrapper';
  const closeBtnClass = !hasLayout ? 'close-button--base' : 'close-button';
  return (
    <div
      {...props}
      className={cx(props.className, styles['fullscreen-base'])}
      data-testid="fullscreen-base"
    >
      {header ? (
        header
      ) : (
        <div className={styles[headerClass]}>
          <Button
            aria-label={intl.formatMessage({ id: 'ui-kit.btn.close' })}
            className={styles[closeBtnClass]}
            data-testid="close-button"
            iconOnly
            onPress={onClose}
            size="large"
            type="button"
            variant="tertiary"
          >
            <IconCrossOutlined aria-hidden="true" />
          </Button>
        </div>
      )}
      {children}
    </div>
  );
}
