import React from 'react';
import { IconApplePay } from '../../assets/icons/icon-apple-pay.tsx';
import { IconBankTransfer } from '../../assets/icons/icon-bank-transfer.tsx';
import { IconCreditCard } from '../../assets/icons/icon-credit-card.tsx';
import { IconPaypal } from '../../assets/icons/icon-paypal.tsx';
import { PaymentMethods } from '../constants/payment-methods.ts';

interface PaymentMethodIconProps {
  method: PaymentMethods;
}

export function PaymentMethodIcon({ method }: PaymentMethodIconProps): React.ReactNode {
  if (method === PaymentMethods.ApplePay) {
    return <IconApplePay aria-hidden="true" />;
  }
  if (method === PaymentMethods.BankTransfer) {
    return <IconBankTransfer aria-hidden="true" />;
  }
  if (method === PaymentMethods.CreditCard) {
    return <IconCreditCard aria-hidden="true" />;
  }
  return <IconPaypal aria-hidden="true" />;
}
