import React from 'react';
import { TextField } from 'react-aria-components';
import { useIntl } from 'react-intl';
import { Label, Input } from '@repo/design-system-kit';
import { DragHandle, SortableList } from '../../../shared';
import styles from './column-names.strict-module.css';

type ItemsId = 'description' | 'quantity' | 'unit_price' | 'vat_rate' | 'subtotal';

export interface Item {
  id: ItemsId;
  value?: string;
}

type Labels = {
  [key in ItemsId]: {
    label: string;
    placeholder: string;
  };
};

export interface ColumnNamesProps {
  items: Item[];
  onColumnInputChange: ({ id, value }: { id: ItemsId; value: string }) => void;
  onSort: (sortedItems: Item[]) => void;
}

export function ColumnNames({
  items,
  onColumnInputChange,
  onSort,
}: ColumnNamesProps): React.ReactNode {
  const { formatMessage } = useIntl();

  const labels: Labels = {
    description: {
      label: formatMessage({
        id: 'receivable-invoices.settings.content-category.column-names.description.label',
      }),
      placeholder: formatMessage({
        id: 'receivable-invoices.settings.content-category.column-names.description.placeholder',
      }),
    },
    quantity: {
      label: formatMessage({
        id: 'receivable-invoices.settings.content-category.column-names.quantity.label',
      }),
      placeholder: formatMessage({
        id: 'receivable-invoices.settings.content-category.column-names.quantity.placeholder',
      }),
    },
    unit_price: {
      label: formatMessage({
        id: 'receivable-invoices.settings.content-category.column-names.unit-price.label',
      }),
      placeholder: formatMessage({
        id: 'receivable-invoices.settings.content-category.column-names.unit-price.placeholder',
      }),
    },
    vat_rate: {
      label: formatMessage({
        id: 'receivable-invoices.settings.content-category.column-names.vat-rate.label',
      }),
      placeholder: formatMessage({
        id: 'receivable-invoices.settings.content-category.column-names.vat-rate.placeholder',
      }),
    },
    subtotal: {
      label: formatMessage({
        id: 'receivable-invoices.settings.content-category.column-names.total.label',
      }),
      placeholder: formatMessage({
        id: 'receivable-invoices.settings.content-category.column-names.total.placeholder',
      }),
    },
  };

  const handleChange = ({ id, value }: { id: ItemsId; value: string }): void => {
    onColumnInputChange({ id, value });
  };

  return (
    <div className={styles.columnNames}>
      <SortableList items={items} onSort={onSort}>
        {(item, draggableProps, index) => (
          <TextField
            className={styles.item}
            data-testid={`column-name-${index}`}
            key={item.id}
            {...draggableProps}
          >
            <div className={styles.itemContent}>
              <DragHandle className="mr-8" id={item.id} />
              <Label>{labels[item.id].label}</Label>
            </div>

            <Input
              className={styles.input}
              onChange={event => { handleChange({ id: item.id, value: event.target.value }); }}
              placeholder={labels[item.id].placeholder}
              value={item.value}
            />
          </TextField>
        )}
      </SortableList>
    </div>
  );
}
