import cx from 'clsx';
import { type ReactNode, type SVGProps } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import type { UniqueIdentifier } from '@dnd-kit/core/dist/types/other';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { IconDragHandleOutlined } from '@repo/monochrome-icons';
import styles from './drag-handle.strict-module.css';

interface DragHandleProps extends Omit<SVGProps<SVGSVGElement>, 'id'> {
  id: UniqueIdentifier;
}

export function DragHandle({ id, className, ...props }: DragHandleProps): ReactNode {
  const { listeners, isDragging } = useSortable({ id });

  const pointerListeners: SyntheticListenerMap | undefined = listeners?.onPointerDown
    ? {
        onPointerDown: listeners.onPointerDown,
      }
    : undefined;

  return (
    <IconDragHandleOutlined
      className={cx(styles.handle, isDragging && styles.dragging, className)}
      height={16}
      width={16}
      {...pointerListeners}
      {...props}
    />
  );
}
