import type { ReactNode } from 'react';
import {
  closestCorners,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import type { UniqueIdentifier } from '@dnd-kit/core/dist/types/other';
import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import { Draggable, type DraggableContainerProps } from './draggable.tsx';

type SortableItem = UniqueIdentifier | { id: UniqueIdentifier };

interface SortableListProps<T> {
  items: T[];
  direction?: 'vertical' | 'horizontal';
  onSort: (sortedItems: T[]) => void;
  children: (item: T, draggableProps: DraggableContainerProps, index: number) => ReactNode;
}

export function SortableList<T extends SortableItem>({
  children,
  direction = 'vertical',
  items,
  onSort,
}: SortableListProps<T>): ReactNode {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const sortingStrategy =
    direction === 'vertical' ? verticalListSortingStrategy : horizontalListSortingStrategy;

  function getId(item: T): UniqueIdentifier {
    if (typeof item === 'string' || typeof item === 'number') {
      return item;
    }

    return item.id;
  }

  function handleDragEnd(event: DragEndEvent): void {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const activeIndex = items.findIndex(item => getId(item) === active.id);
      const overIndex = items.findIndex(item => getId(item) === over?.id);
      const newItems = arrayMove(items, activeIndex, overIndex);
      onSort([...newItems]);
    }
  }

  return (
    <DndContext
      collisionDetection={closestCorners}
      modifiers={[restrictToParentElement]}
      onDragEnd={handleDragEnd}
      sensors={sensors}
    >
      <SortableContext items={items} strategy={sortingStrategy}>
        {items.map((item, index) => (
          <Draggable id={getId(item)} key={getId(item)}>
            {draggableProps => children(item, draggableProps, index)}
          </Draggable>
        ))}
      </SortableContext>
    </DndContext>
  );
}
