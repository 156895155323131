import React from 'react';
import { useIntl } from 'react-intl';
import styles from './type-cell.strict-module.css';

interface TypeCellProps {
  type: string;
}

export function TypeCell({ type }: TypeCellProps): React.ReactElement {
  const { formatMessage } = useIntl();

  let typeCopy: string;

  switch (type) {
    case 'supplier_invoice':
      typeCopy = formatMessage({ id: 'transactions.operation-types.supplier-invoice' });
      break;
    case 'receivable_invoice':
      typeCopy = formatMessage({ id: 'transactions.operation-types.client-invoice' });
      break;
    case 'subscription':
      typeCopy = formatMessage({ id: 'transactions.operation-types.subscription' });
      break;
    case 'transfer':
      typeCopy = formatMessage({ id: 'transactions.operation-types.transfer' });
      break;
    case 'direct_debit':
      typeCopy = formatMessage({ id: 'transactions.operation-types.direct-debit' });
      break;
    case 'check':
      typeCopy = formatMessage({ id: 'transactions.operation-types.cheque' });
      break;
    case 'fee':
      typeCopy = formatMessage({ id: 'transactions.operation-types.biller' });
      break;
    case 'taxes':
      typeCopy = formatMessage({ id: 'transactions.operation-types.tax' });
      break;
    default:
      typeCopy = type;
  }

  return <div className={styles.typeCell}>{typeCopy}</div>;
}
