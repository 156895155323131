import React from 'react';
import { useIntl } from 'react-intl';
import { TextField } from '@repo/design-system-kit';
import { ColumnNames, type ColumnNamesProps } from './column-names.tsx';
import styles from './content-category.strict-module.css';

interface ContentCategoryProps extends ColumnNamesProps {
  invoiceTitle?: string;
  quoteTitle?: string;
  onInvoiceTitleChange: (value: string) => void;
  onQuoteTitleChange: (value: string) => void;
}

export function ContentCategory({
  invoiceTitle,
  quoteTitle,
  onInvoiceTitleChange,
  onQuoteTitleChange,
  items,
  onSort,
  onColumnInputChange,
}: ContentCategoryProps): React.ReactNode {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.container}>
      <p className="body-2">
        {formatMessage({
          id: 'receivable-invoices.settings.content-category.subtitle',
        })}
      </p>
      <div className={styles.section}>
        <h4 className="title-4">
          {formatMessage({
            id: 'receivable-invoices.settings.content-category.document-titles.title',
          })}
        </h4>
        <TextField
          data-testid="invoice-title-input"
          defaultValue={formatMessage({
            id: 'receivable-invoices.settings.content-category.document-titles.invoice.prefilled-placeholder',
          })}
          label={formatMessage({
            id: 'receivable-invoices.settings.content-category.document-titles.invoice.label',
          })}
          name="invoice-title"
          onChange={onInvoiceTitleChange}
          placeholder={formatMessage({
            id: 'receivable-invoices.settings.content-category.document-titles.invoice.placeholder',
          })}
          value={invoiceTitle}
        />
        <TextField
          data-testid="quote-title-input"
          defaultValue={formatMessage({
            id: 'receivable-invoices.settings.content-category.document-titles.quote.prefilled-placeholder',
          })}
          label={formatMessage({
            id: 'receivable-invoices.settings.content-category.document-titles.quote.label',
          })}
          name="invoice-title"
          onChange={onQuoteTitleChange}
          placeholder={formatMessage({
            id: 'receivable-invoices.settings.content-category.document-titles.quote.placeholder',
          })}
          value={quoteTitle}
        />
      </div>
      <div className={styles.section}>
        <h4 className="title-4">
          {formatMessage({
            id: 'receivable-invoices.settings.content-category.column-names.title',
          })}
        </h4>
        <ColumnNames items={items} onColumnInputChange={onColumnInputChange} onSort={onSort} />
      </div>
    </div>
  );
}
