import * as React from 'react';
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
  type ColumnDef,
  type SortingState,
} from '@tanstack/react-table';
import cx from 'clsx';
import { useState } from 'react';
import styles from './base-table.strict-module.css';
import { Pagination } from './pagination.tsx';
import type { PaginationMeta, PaginationProps } from './pagination.tsx';
import { SortIcon } from './sort.tsx';

interface BaseTableProps<TData> {
  data: TData[];
  columns: ColumnDef<TData>[];
  enableRowHover?: boolean;
  enablePagination?: boolean;
  initialSorting?: SortingState;
  pageSize?: number;
  pageSizes?: number[];
  className?: string;
  total: number;
  isLoading?: boolean;
  loadingComponent?: React.ReactNode;
}

export function BaseTable<TData>({
  data,
  columns,
  enableRowHover = false,
  enablePagination = true,
  initialSorting = [],
  pageSize = 25,
  pageSizes = [25, 50, 100],
  total,
  isLoading = false,
  loadingComponent,
}: BaseTableProps<TData>): React.JSX.Element {
  const [sorting, setSorting] = useState<SortingState>(initialSorting);
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...(enablePagination && {
      getPaginationRowModel: getPaginationRowModel(),
      initialState: {
        pagination: {
          pageSize,
        },
        sorting: initialSorting,
      },
    }),
  });

  const paginationMeta: PaginationMeta = {
    pageCount: table.getPageCount(),
    currentPage: table.getState().pagination.pageIndex,
    perPage: table.getState().pagination.pageSize,
    totalCount: table.getPrePaginationRowModel().rows.length,
    prevPage: table.getCanPreviousPage()
      ? () => {
          table.previousPage();
        }
      : undefined,
    nextPage: table.getCanNextPage()
      ? () => {
          table.nextPage();
        }
      : undefined,
  };

  const paginationProps: PaginationProps = {
    responseMeta: paginationMeta,
    pageSizes,
    updatePerPage: (updatedPageSize: number) => {
      table.setPageSize(updatedPageSize);
    },
    updatePage: (page: number) => {
      table.setPageIndex(page);
    },
  };

  if (data.length === 0 && !isLoading) {
    return <div />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles['table-wrapper']}>
        <table className={styles.table} data-testid="table">
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr data-testid="table-header-row" key={headerGroup.id}>
                <th aria-hidden="true" className={styles['empty-header']} />
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      className={styles['header-cell']}
                      data-testid="visible-header"
                      key={header.id}
                      scope="col"
                    >
                      {header.isPlaceholder ? null : (
                        <button
                          className={styles.button}
                          onClick={header.column.getToggleSortingHandler()}
                          type="button"
                        >
                          {flexRender(header.column.columnDef.header, header.getContext())}
                          {header.column.columnDef.enableSorting ? (
                            <SortIcon
                              isActive={Boolean(
                                table.getState().sorting.find(sort => sort.id === header.column.id)
                              )}
                              isAscending={header.column.getIsSorted() === 'asc'}
                            />
                          ) : null}
                        </button>
                      )}
                    </th>
                  );
                })}
                <th aria-hidden="true" className={styles['empty-header']} />
              </tr>
            ))}
          </thead>

          <tbody>
            {isLoading
              ? Array.from({ length: 5 }, (_, i) =>
                  React.cloneElement(loadingComponent as React.ReactElement, { key: i })
                )
              : table.getRowModel().rows.map(row => (
                  <tr
                    className={cx(styles['table-row'], enableRowHover && styles['table-row-hover'])}
                    data-testid="table-body-row"
                    key={row.id}
                  >
                    <td aria-hidden="true" className={styles['empty-cell']} />
                    {row.getVisibleCells().map(cell => (
                      <td className={styles['table-cell']} key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                    <td aria-hidden="true" className={styles['empty-cell']} />
                  </tr>
                ))}
          </tbody>
        </table>
      </div>
      {enablePagination && total && total > pageSize ? <Pagination {...paginationProps} /> : null}
    </div>
  );
}
