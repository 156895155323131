import type { ReactElement, ComponentPropsWithoutRef } from 'react';
import cx from 'clsx';
import styles from './avatar.strict-module.css';

export type AvatarSize = '64' | '56' | '48' | '40' | '32' | '30' | '24' | '16';

interface AvatarProps extends ComponentPropsWithoutRef<'img'> {
  size?: AvatarSize;
  name?: string;
  withBorder?: boolean;
  className?: string;
  isDisabled?: boolean;
}

export function Avatar({
  loading = 'eager',
  alt = '',
  size = '32',
  name = '',
  isDisabled = false,
  withBorder = false,
  className,
  ...props
}: AvatarProps): ReactElement {
  let initials = '';

  const getInitials = (fullName: string): string =>
    fullName
      .split(' ')
      .filter(Boolean)
      .map(initial => (initial[0] ? initial[0].toUpperCase() : ''))
      .slice(0, 2)
      .join('');

  if (name) {
    initials = getInitials(name);
  }

  return (
    <div
      className={cx(
        styles['avatar-wrapper'],
        styles[`size-${size}`],
        name && styles['avatar-initials'],
        withBorder && styles['avatar-borders'],
        isDisabled && styles['avatar-disabled']
      )}
    >
      {name ? (
        <span>{initials}</span>
      ) : (
        <img
          alt={alt}
          className={cx(styles.avatar, className)}
          data-test-avatar
          loading={loading}
          {...props}
        />
      )}
    </div>
  );
}
