import React, { isValidElement } from 'react';
import { Avatar } from '@repo/design-system-kit';
import styles from './counterparty.strict-module.css';

interface CounterpartyProps {
  title: string;
  subtitle?: React.ReactNode;
  icon?: React.ReactNode;
}

export function Counterparty({ title, icon, subtitle }: CounterpartyProps): React.ReactNode {
  return (
    <div className={styles.container}>
      {isValidElement(icon) && <Avatar name={title} />}
      <div>
        <p className={styles.title} data-testid="counterparty-title">
          {title}
        </p>
        <Subtitle subtitle={subtitle} />
      </div>
    </div>
  );
}

function Subtitle({ subtitle }: { subtitle: React.ReactNode }): React.ReactNode {
  if (typeof subtitle === 'string') {
    return (
      <p className={styles.subtitle} data-testid="counterparty-subtitle">
        {subtitle}
      </p>
    );
  }

  if (isValidElement(subtitle)) {
    return (
      <div className={styles.subtitle} data-testid="counterparty-subtitle">
        {subtitle}
      </div>
    );
  }

  return null;
}
