import { type CSSProperties, type ReactNode } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { UniqueIdentifier } from '@dnd-kit/core/dist/types/other';
import type { DraggableAttributes } from '@dnd-kit/core';
import type { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

export interface DraggableContainerProps extends DraggableAttributes {
  style: CSSProperties;
  ref: (node: HTMLElement | null) => void;
}

interface DraggableProps {
  id: UniqueIdentifier;
  children: (draggableProps: DraggableContainerProps) => ReactNode;
}

export function Draggable({ id, children }: DraggableProps): ReactNode {
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({
    id,
  });

  const keyboardListeners: SyntheticListenerMap | undefined = listeners?.onKeyDown
    ? {
        onKeyDown: listeners.onKeyDown,
      }
    : undefined;

  return children({
    ...attributes,
    ...keyboardListeners,
    style: {
      transform: CSS.Transform.toString(
        transform
          ? {
              x: transform.x,
              y: transform.y,
              scaleX: isDragging ? 1.01 : 1,
              scaleY: isDragging ? 1.01 : 1,
            }
          : null
      ),
      transition,
      zIndex: isDragging ? 1 : undefined,
    },
    ref: setNodeRef,
  });
}
