import type { SelectorToggleProps } from '@repo/design-system-kit';
import { SelectorToggle } from '@repo/design-system-kit';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { PaymentMethods } from '../constants/payment-methods.ts';
import { CreditCardIcons } from '../internal/credit-card-icons.tsx';
import { PaymentMethodIcon } from '../internal/payment-method-icon.tsx';
import { PaymentMethodTitle } from '../payment-method-title/index.ts';

interface PaymentMethodSelectorToggleProps extends Omit<SelectorToggleProps, 'icon' | 'title'> {
  method: PaymentMethods;
}

export function PaymentMethodSelectorToggle({
  method,
  ...props
}: PaymentMethodSelectorToggleProps): React.ReactNode {
  const { locale } = useIntl();

  const additionalContent: React.ReactElement | undefined =
    method === PaymentMethods.CreditCard ? <CreditCardIcons className="mr-16" /> : undefined;

  return (
    <SelectorToggle
      additionalContent={additionalContent}
      data-test-payment-method-selector={method}
      icon={<PaymentMethodIcon method={method} />}
      title={<PaymentMethodTitle method={method} selectedLocale={locale} />}
      {...props}
    />
  );
}
