import React from 'react';
import styles from './expected-date.strict-module.css';

interface ExpectedDateProps {
  date: string;
}

export function ExpectedDate({ date }: ExpectedDateProps): React.ReactElement {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  return <div className={styles.expectedDate}>{formattedDate}</div>;
}
