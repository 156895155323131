import type { ReactElement, ReactNode } from 'react';
import { useRef, useState } from 'react';
import type { ButtonProps as AriaButtonProps } from 'react-aria-components';
import {
  Button,
  Collection,
  Group,
  Menu,
  MenuItem,
  MenuTrigger,
  Popover,
} from 'react-aria-components';
import cx from 'clsx';
import { useIntl } from 'react-intl';
import { IconChevronBottomOutlined } from '@repo/monochrome-icons';
import btnStyles from '../../primitives/button.strict-module.css';
import { Spinner } from '../spinner';
import styles from './dropdown-button.strict-module.css';

export interface BaseDropdownButtonProps {
  isLoading?: boolean;
  items: {
    label: string;
    onSelect: () => void;
    disabled?: boolean;
  }[];
}

interface DropdownButtonProps extends AriaButtonProps, BaseDropdownButtonProps {
  children: ReactNode;
  style?: React.CSSProperties;
}

export function DropdownButton({
  children,
  isLoading = false,
  isDisabled = false,
  items,
  className,
  style,
  ...props
}: DropdownButtonProps): ReactElement {
  const intl = useIntl();
  const mainButtonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={cx('body-1', styles.container, className)} style={style}>
      <Group className={styles.buttonGroup} isDisabled={isDisabled}>
        <Button
          className={cx(
            btnStyles.btn,
            btnStyles.primary,
            btnStyles['btn--small'],
            styles.mainButton
          )}
          isDisabled={isDisabled || isLoading}
          ref={mainButtonRef}
          {...props}
        >
          <div className={styles.buttonContent}>{children}</div>
          {isLoading ? <Spinner className={styles.spinner} /> : null}
        </Button>
        <MenuTrigger onOpenChange={setIsOpen}>
          <Button
            aria-label={
              isOpen
                ? intl.formatMessage({ id: 'a11y-buttons-close-aria-label' })
                : intl.formatMessage({ id: 'a11y-buttons-open-aria-label' })
            }
            className={cx(
              btnStyles.btn,
              btnStyles.primary,
              btnStyles['btn--small'],
              styles.triggerButton
            )}
            isDisabled={isDisabled || isLoading}
          >
            <IconChevronBottomOutlined />
          </Button>

          <Popover
            className={cx('body-2', styles.popover)}
            offset={4}
            placement="bottom start"
            triggerRef={mainButtonRef}
          >
            <Menu className={styles.menu}>
              <Collection items={items}>
                {item => (
                  <MenuItem
                    className={styles.menuItem}
                    id={item.label}
                    isDisabled={item.disabled}
                    key={item.label}
                    onAction={item.onSelect}
                  >
                    {item.label}
                  </MenuItem>
                )}
              </Collection>
            </Menu>
          </Popover>
        </MenuTrigger>
      </Group>
    </div>
  );
}
